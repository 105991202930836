<template>
  <div class="car-selection">
    <Header :isHeader="true" :back="true" :step="currentStepIndex" />
    <Wrapper :ismain="true">
      <Title titleStyle="title" :text="translateString('selectVehicle')" />
      <HorizontalScroll>
        <CardOverflow
          v-for="(car, index) in vehicles"
          :key="index"
          :class="car.selected ? 'selected' : ''"
        >
          <p>
            <span class="big-text">{{ car.plate }}</span
            ><br />
            <span class="text">{{ car.name }}</span>
          </p>
          <Input
            v-if="car.selected"
            :id="'kms-' + car.plate"
            :placeholder="translateString('insertKms')"
            :errorMsg="translateString('insertValidValueWNumber')"
            class="small selected-highlight"
            type="number"
            autocomplete="number"
            @updateInput="updateInputSaved($event, 'kms-' + car.plate)"
          />
          <Button
            v-if="car.selected"
            :id="'btn-' + car.plate"
            :label="translateString('continue')"
            btnClass="disabled btn-small "
            @action="nextSaved(car.plate, 'btn-' + car.plate)"
          />
          <Button
            v-else
            :id="'btn-' + car.plate"
            :label="translateString('select')"
            btnClass="btn-small "
            @action="selectVehicle(car)"
          />
        </CardOverflow>
        <CardOverflow>
          <p class="big-text">{{ translateString("otherVehicle") }}</p>
          <Input
            id="plate"
            :placeholder="translateString('insertPlate')"
            class="small"
            @updateInput="updateInput($event, 'plate')"
            type="plate"
            autocomplete="text"
            :errorMsg="translateString('insertValidPlate')"
          />
          <Input
            id="kms"
            :placeholder="translateString('insertKms')"
            class="small fake-placeholder"
            @updateInput="updateInput($event, 'kms')"
            type="number"
            autocomplete="number"
            :errorMsg="translateString('insertValidValueWNumber')"
          />
          <Button
            id="getCarByPlate"
            :label="translateString('select')"
            :btnClass="ctaBtn"
            @action="next('getCarByPlate')"
          />
        </CardOverflow>
      </HorizontalScroll>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import CardOverflow from "@/components/Card/CardOverflow.vue";
import Header from "@/components/Header/Header.vue";
import HorizontalScroll from "@/components/HorizontalScroll/HorizontalScroll.vue";
import Input from "@/components/Inputs/Input.vue";
import Modal from "@/components/Modal/Modal.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";
import { mapGetters } from "vuex";

import { mapActions } from "vuex";

import { translateString } from "@/functions/functions.js";
import { validateInputs } from "@/functions/validations.js";

export default {
  beforeDestroy() {
    this.$store.dispatch('setNavigatedFromHomepage', false);
  },
  name: "CarSelecion",
  components: {
    Button,
    CardOverflow,
    Header,
    HorizontalScroll,
    Input,
    Modal,
    Title,
    Wrapper,
  },
  data() {
    return {
      ctaBtn: "disabled  btn-small ",
      carData: {
        plate: "",
        kms: "",
      },
      vehicles: this.$store.state.cgVehicles.map((el) => {
        el.plate = el.Plate;
        el.name = el.Brand + " " + el.Model + " " + el.Version;
        el.selected = false;
        return el;
      }),
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    carInfoReady() {
      return this.$store.state.carByPlateReady;
    },
    allReady() {
      return this.$store.state.allReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    hasCG() {
      return this.$store.state.hasCG;
    },
    ...mapGetters(["getCurrentStepIndex"]),
    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    this.$store.dispatch("clearSelectedInfo", "Homepage");

    if (!this.hasCG && this.$store.state.navigatedFromHeader) {
      this.$router.push({ name: "CarConfirmation" });
      this.$store.dispatch("setNavigatedFromHomepage", false);
    } else if (!this.$store.state.navigatedFromHeader && this.$store.state.avancarCarSelection == true) {
      this.$router.push({ name: "homePage" });
    }
  },
  methods: {
    translateString,
    selectVehicle(e) {
      this.vehicles = this.vehicles.map((el) => {
        if (el.ID == e.ID) {
          el.selected = true;
        } else {
          el.selected = false;
        }
        return el;
      });
    },
    ...mapActions(["setCurrentStepIndex"]),
    updateInputSaved(value, field) {
      const plate = field.replace("kms-", "");
      this.vehicles.find((el) => {
        el.kms = value;
        return plate == el.plate;
      });

      if (value == "") {
        document.querySelector("#btn-" + plate).classList.add("disabled");
      } else {
        document.querySelector("#btn-" + plate).classList.remove("disabled");
      }
    },
    updateInput(value, field) {
      this.carData[field] = value;
    },
    nextSaved(plate, id) {
      const car = this.vehicles.find((el) => {
        return plate == el.plate;
      });
      if (validateInputs(["kms-" + plate]).length == 0) {
        this.$store.dispatch("getCarByPlate", {
          plate: car.plate,
          kms: car.kms.replace(/\s+/g, ""),
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      }
    },
    next(id) {
      this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex + 1);
      if (validateInputs(["kms"]).length == 0) {
        this.$store.dispatch("setCurrentStepIndex", this.currentStepIndex - 1);
        this.$store.dispatch("getCarByPlate", {
          plate: this.carData.plate,
          kms: this.carData.kms.replace(/\s+/g, ""),
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: id,
        });
      }
    },
  },
  watch: {
    carData: {
      handler(carData) {
        if (Object.values(carData).some((x) => x === null || x === "")) {
          this.ctaBtn = "disabled btn-small ";
        } else {
          this.ctaBtn = "btn-small ";
        }
      },
      deep: true,
    },
    carInfoReady() {
      if (this.carInfoReady == true) {
        if (this.$store.state.carCombo.length > 1) {
          this.$router.push({ name: "CarIdentification" });
        } else {
          this.$router.push({ name: "CarConfirmation" });
          this.$store.dispatch("selectOption", {
            selectedValue: "carComboSelected",
            value: {
              comboLabel:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleDesc
                  : "",
              comboId:
                this.$store.state.carCombo != ""
                  ? this.$store.state.carCombo[0].typeVehicleId
                  : 0,
              comboSelectedByUser: false,
            },
          });
        }
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    allReady() {
      if (this.allReady == true) {
        this.$router.push({ name: "PlateNotFound" });
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },

    $route(to) {
      // Despache a ação para atualizar o índice do passo
      this.setCurrentStepIndex(
        this.steps.findIndex((step) => step.route === to.name)
      );
      this.$store.dispatch('setNavigatedFromHomepage', false);
    },
  },
};
</script>
