<template>
  <div class="card-overflow">
    <div v-if="image">
      <img class="car-image-overflow" :src="image" alt="card" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardOverflow",
  props: {
    image: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "/Card";
</style>
